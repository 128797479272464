/* @import "~antd/dist/antd.css"; */

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

section.app-content {
  margin: 16px;
  background: #fff;
  padding: 16px;
}

.house_section, .outer_section {
  padding: 16px 24px;
  overflow-y: auto;
  @media screen and (max-width: 900px) {
    padding: 8px;
  }
}


.inner_section {
  background: #fff;
  padding: 16px;
  border-radius: 4px;
  margin: 0 0 16px;
  overflow-x: auto;
}

span.payment {
  padding: 4px 8px;
  display: inline-block;
  background: green;
  border-radius: 4px;
  color: #fff;
}

span.payment.payment__open {
  padding: 4px 8px;
  display: inline-block;
  background: red;
  border-radius: 4px;
  color: #fff;
}

.dashboard-available td.booked {
  border: 1px solid #66AC3A;
  background: #66AC3A;
}
.dashboard-available td.booked.owner {
  border: 1px solid #009ACD;
  background: #009ACD;
}

p[data-f-id="pbf"] {
  display: none;
}

.bookings_table
  .ant-table-row-expand-icon {
    display: none;
  }


.App-link {
  color: #61dafb;
}

.thumb {
  width: 100px;
}

.row {
  display: flex;
  flex-direction: row;
  padding: 8px;
}

.discount_not_used {
  opacity: .5;
}

@media screen and (max-width: 992px) {
      .house_section {
        padding: 8px 8px 8px 0;
      }

      .inner_section {
        padding: 4px 8px;
      }
      .ant-page-header {
        padding: 8px 12px;
      }
    }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wizard_contracts {
  transition: all 0.2s ease-in-out;
  padding: 64px 16px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
  .wizard_contracts.hide {
    display: none;
  }

  .wizard_contracts_side {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
  }

  .contract-types {
    padding: 16px;
    width: 100%;
  }
  .contract-types--single {
    padding: 16px;
    margin: 0 0 16px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    display: grid;
    grid-template-columns: 2fr 1fr;
  }
  .contract-types--single.selected {
    background-color: #66AC3A;
    color: #fff;

  }

  .amount_of_objects {
    grid-row: 2;
  }

  .contract_price {
    font-size: 1.8rem;
    font-weight: bold;
    grid-column: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    align-self: center;
    justify-self: center;
    text-align: center;
  }
  .old-price {
    color: #ccc;
    font-weight: normal;
    text-decoration: line-through;
  }

  .contract_price span {
    display: block;
    font-size: .9rem;
    font-style: italic;
    font-weight: normal;
  }